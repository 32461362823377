import { SignIn } from "@clerk/clerk-react";

export default function SignInPage() {
  return (
    <div className="w-full h-screen flex flex-col gap-4 justify-center items-center bg-pink-100/20">
      <SignIn signUpUrl="/sign-up" />

    </div>
  );
}
